import { IAccordion } from '../../components/Accordion/interfaces';
import { IModxService } from '../../redux/services/InterfacesModx';
import { EnumUrlsPages } from '../../services/Enum';
import { Lang } from '../../services/Interfaces';

export const formatServices = (services: IModxService[], lang: Lang): IAccordion[] => {
  const result: IAccordion[] = [];
  services.forEach(({ name, description, full_description }, i) => {
    result.push({
      title: name,
      content: full_description ? full_description : description,
      link: EnumUrlsPages.projects,
      textLink: lang === 'En' ? 'view projects' : 'примеры работ',
    });
  });

  return result;
};
