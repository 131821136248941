import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { Swiper, SwiperSlide, Autoplay, Pagination, TypeSwiper, EffectFade } from '../../services/ImportsSwiper';
import Button from '../../components/Button/Button';
import { Props } from './interfaces';
import './SliderVideo.scss';
import './SliderVideo-media.scss';
import { baseURLModx } from '../../redux/services/APIRequestsModx';

export const SliderVideo = ({ title, slides, subtitle }: Props) => {
  const [slider, setSlider] = useState<TypeSwiper>();
  const [prevIndex, setPrevIndex] = useState<number | null>(0);

  // Состояние для отключения стрелок
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const settings = {
    modules: [Autoplay, Pagination, EffectFade],
    spaceBetween: 20,
    className: 'video-slider-gallery',
    slidesPerView: 1,
    autoplay: false,
    speed: 500,
    onInit: (swiper: TypeSwiper) => setSlider(swiper),
    onSlideChange: (swiper: TypeSwiper) => {
      if (prevIndex !== null) {
        stopVideoOnSlide(prevIndex);
      }
      setPrevIndex(swiper.activeIndex);
      setIsBeginning(swiper.isBeginning);
      setIsEnd(swiper.isEnd);
    },
  };

  const stopVideoOnSlide = (index: number) => {
    const currentSlide = slider?.slides[index];
    const iframeElement = currentSlide?.querySelector('iframe');
    currentSlide?.querySelector(".video-slider-gallery__image_bg")?.classList.remove("hide");
    currentSlide?.querySelector(".video-slider-gallery__play")?.classList.remove("hide");

    if (index === null || !slider) return;
    const prevSlide = slider.slides[index]?.querySelector('iframe');
    if (prevSlide && prevSlide.tagName === 'IFRAME') {
      const src = new URL(prevSlide.src);
      src.searchParams.delete("autoplay");
      prevSlide.src = src.toString();
    }
  };

  const playVideo = (index: number) => {
    const currentSlide = slider?.slides[index];
    const iframeElement = currentSlide?.querySelector('iframe');
    currentSlide?.querySelector(".video-slider-gallery__image_bg")?.classList.add("hide");
    currentSlide?.querySelector(".video-slider-gallery__play")?.classList.add("hide");


    if (iframeElement) {
      const src = iframeElement.src;
      const newSrc = src.includes("autoplay=1") ? src : `${src}${src.includes("?") ? "&" : "?"}autoplay=1`;
      iframeElement.src = "";
      iframeElement.src = newSrc;
    }
  }


  return (
    <>
      <div className="video-slider-gallery__header">
        {title ? <h2 className="slider-gallery__title">{title}</h2> : null}
        {subtitle ? <p className="slider-gallery__subtitle">{subtitle}</p> : null}
      </div>
      <Swiper {...settings} className="video-slider-gallery slider-gallery--full-screen">
        {slides.map((item, i) => (
          <SwiperSlide key={i} className="video-slider-gallery__slide">
            {item.video ? (
              <>
                <button
                  type="button"
                  onClick={() => playVideo(i)}
                  className="video-slider-gallery__play"
                  id="play-video"></button>
                <img
                  src={item.video_preview}
                  alt=""
                  className="video-slider-gallery__image_bg"
                  loading="lazy" />
                <div
                  className="video-slider-gallery__image"
                  dangerouslySetInnerHTML={{ __html: item.video }}></div>
              </>
            ) : (
              <img
                src={`${baseURLModx}/${item.image}`}
                alt=""
                className="video-slider-gallery__image_bg"
                loading="lazy" />
            )}

            {item.button_text && <Button text={item.button_text} link={item.button_link} BaseLink="true" />}
          </SwiperSlide>
        ))}
        <div className="video-slider-gallery__arrows">
          <div
            className={`video-slider-gallery__arrow video-slider-gallery__arrow_prev ${isBeginning ? "disabled" : ""
              }`}
            onClick={() => !isBeginning && slider?.slidePrev()}
          ></div>

          <div
            className={`video-slider-gallery__arrow video-slider-gallery__arrow_next ${isEnd ? "disabled" : ""
              }`}
            onClick={() => !isEnd && slider?.slideNext()}
          ></div>
        </div>
      </Swiper>
    </>
  );
};

export default SliderVideo;