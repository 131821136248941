import { useEffect, useState } from 'react';
import './NextProject.scss';
import { baseURLModx } from '../../redux/services/APIRequestsModx';
import { Link } from 'react-router-dom';
import { connect, WithStore, MapStateToProps, MapDispatchToProps } from '../../redux/services/Imports';
import { Lang } from '../../services/Interfaces';

type NextProjectPropsType = {
  nextId?: string;
  nextPreview?: string;
  lang: Lang;
};

export const NextProject = ({ nextId, nextPreview, lang }: NextProjectPropsType) => {
  const nextText = lang === "En" ? "Next project" : "Следующий проект";

  const [hidden, setHidden] = useState(true);
  const [MousePosition, setMousePosition] = useState({
    left: 0,
    top: 0
  })

  function cursorEvent(ev: any) {
    if (!hidden) {
      let target = ev.target;
      let targetCoords = target.getBoundingClientRect();

      setMousePosition({ left: ev.clientX, top: ev.clientY - targetCoords.top });
    }
  }

  return (
    <div className="next-project" onMouseMove={(ev) => cursorEvent(ev)}>
      <div className="next-project__container">
        <a href={`/${lang === "En" ? "en/" : ""}case/${nextId}`} className={!hidden ? "items-wrap items-wrap--stop" : "items-wrap"}>
          <span className="items marquee">
            <span
              className="item"
              onMouseEnter={() => setHidden(false)}
              onMouseLeave={() => setHidden(true)}
            >
              {nextText}
            </span>
            <span
              className="item"
              onMouseEnter={() => setHidden(false)}
              onMouseLeave={() => setHidden(true)}
            >
              {nextText}
            </span>
          </span>
          <span aria-hidden="true" className="items marquee">
            <span
              className="item"
              onMouseEnter={() => setHidden(false)}
              onMouseLeave={() => setHidden(true)}
            >
              {nextText}
            </span>
            <span
              className="item"
              onMouseEnter={() => setHidden(false)}
              onMouseLeave={() => setHidden(true)}
            >
              {nextText}
            </span>
          </span>
        </a>
      </div>
      <div className={hidden ? "next-project__preview next-project__preview--hide" : "next-project__preview"} style={{ left: MousePosition.left, top: MousePosition.top }}>
        <img src={`${baseURLModx}/${nextPreview}`} alt="" />
      </div>
    </div>
  );
};

export default WithStore()(connect(MapStateToProps, MapDispatchToProps)(NextProject));